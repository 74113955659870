<template>
  <a-spin :spinning="ifSub">
    <a-form :form="form">
      <a-form-item label="处理人" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-decorator="['handlePerson',{ rules: [{ required: true, message: '现场处理人不能为空' }] }]" placeholder="请输入现场处理人"></a-input>
      </a-form-item>
      <a-form-item label="处理结果" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input type="textarea" rows="4" v-decorator="['handleResult']" placeholder="请输入现场处理结果"></a-input>
      </a-form-item>
    </a-form>
  </a-spin>
</template>
<script>
  import { damageReportFormVO } from './common/common'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'handleForm',
    props: {
      type: {
        type: String,
        default: 'add'
      }
    },
    data () {
      return {
        form: this.$form.createForm(this),
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        ifSub: false,
        formItem: damageReportFormVO(),
      }
    },
    created () {
    },
    computed: {
      currentUser () {
        return this.$store.getters.currentUser
      }
    },
    mounted () {
    },
    methods: {
      setVOFields (values) {
        this.formItem.handleResult = values.handleResult
        this.formItem.handlePerson = values.handlePerson
      },
      handlePerson() {
        this.form.setFieldsValue({
          'handlePerson': this.currentUser.name
        })
      },
      handle (id) {
        this.formItem.handleStatus = 'PROCESSED'
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.ticket.damageReport.update,
                params: { id: id },
                data: this.formItem,
                noTips: true,
                success: (data) => {
                  //清空表单数据
                  this.formItem = damageReportFormVO()
                  this.form.resetFields()
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('handleSuccess')
                },
                error: () => {
                  this.$emit('handleError')
                }
              })
            }
          })
      },
    }
  }
</script>

<style scoped></style>